<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Informasi Pohon </strong><small> Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateInformasi">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="judul_informasi_pohon"
                  label="Judul Informasi Pohon"
                  placeholder="Judul Informasi Pohon"
                />
              </CCol>

              <CCol sm="12">
                <CTextarea
                  v-model="isi_informasi_pohon"
                  label="Isi Informasi Pohon"
                  placeholder="Isi Informasi Pohon"
                />  
              </CCol>           
              
              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Informasi </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                    <input type="hidden" v-model="foto2">
                  </div>
                </div>  
              </CCol>

            </CRow>        
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/informasi">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data Informasi Pohon gagal diinput.
    </CModal>
   
  </div>   
</template>
<script>

import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      judul_informasi_pohon : "",
      isi_informasi_pohon : "",
      foto_informasi_pohon : "",
      foto_informasi_text : "",
      foto2:"",
      placeholder : "Pilih File",
      selected: [], // Must be an array reference!
      show: true,
      modalError:false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.loadData();
  this.showInformasi();
  },
  methods: {

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.foto_informasi_text = fileData.name;
      this.foto_informasi_pohon = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },

    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      // axios.get(process.env.VUE_APP_BASE_URL+"api-sippohon/public/informasi")
      //   .then((response) => {
      //         this.pohon=response.data;
      //   })
    },
    updateInformasi: function(){
      
      const formData = new FormData()
      
      formData.append('id_informasi_pohon', this.$route.params.id_informasi_pohon) 
      formData.append('judul_informasi_pohon', this.judul_informasi_pohon)
      formData.append('isi_informasi_pohon', this.isi_informasi_pohon)
      formData.append('foto2', this.foto2)

      if(this.foto_informasi_text){
          // alert(this.foto_informasi_pohon);
          formData.append('foto_informasi_pohon', this.foto_informasi_pohon, this.foto_informasi_pohon.name)
          formData.append('foto_informasi_text', this.foto_informasi_pohon.name)
      } 

      axios.post(process.env.VUE_APP_BASE_URL+"informasipohon/"+this.$route.params.id_informasi_pohon, formData)
        .then((response) => {
            console.log(response.data.status)
            if(response.data.status=='success'){
              this.$router.push('/informasi');
            }
            else{
              this.modalError = true;
            }
        })
    },
    
    showInformasi: function(event){
      // Simple POST request with a JSON body using axios
      const informasi = { 
                    judul_informasi_pohon:this.judul_informasi_pohon,
                    isi_informasi_pohon: this.isi_informasi_pohon, 
                    tgl_posting: this.tgl_posting
                    };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"informasipohon/"+this.$route.params.id_informasi_pohon)
        .then((response) => {
              const json = response.data.data;
              this.judul_informasi_pohon=json.judul_informasi_pohon;
              this.isi_informasi_pohon=json.isi_informasi_pohon;
              this.tgl_posting=json.tgl_posting;
              this.placeholder=json.foto_informasi_pohon;
              this.foto2=json.foto_informasi_pohon;
            
        })
    }
  }
}
</script>